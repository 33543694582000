
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    isActive: {
      type: Boolean,
    },
  },
})
