
import { computed, defineComponent, ref } from 'vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import SelectEntityContentTab from '@/components/modals/shared/selectEntity/SelectEntityContentTab.vue'
import SelectEntityContentTitle from '@/components/modals/shared/selectEntity/SelectEntityContentTitle.vue'
import { capitalizeFirstLetter } from '@/services/string'
import type { PropType } from 'vue'
import type { SelectEntityCategoryType } from '@/definitions/modals/shared/selectEntity/type'

export default defineComponent({
  components: {
    SelectEntityContentTab,
    SelectEntityContentTitle,
    TmSearch,
  },
  props: {
    currentTab: {
      type: String,
    },
    categories: {
      type: Array as PropType<SelectEntityCategoryType[]>,
      required: true,
    },
    searchPlaceholder: {
      type: String,
    },
    tabBtnText: {
      type: String,
    },
    tabSquareIcon: {
      type: Boolean,
    },
  },
  setup(props) {
    const search = ref('')
    const tabs = computed(() => props.categories.find((e: SelectEntityCategoryType) => e.name === props.currentTab)!.data)

    return {
      search,
      tabs,
      capitalizeFirstLetter,
    }
  },
})
