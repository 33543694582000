import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a3f183c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-entity-content" }
const _hoisted_2 = { class: "mb-6 d-flex align-center justify-between" }
const _hoisted_3 = { class: "headline-18 lh-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_search = _resolveComponent("tm-search")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.capitalizeFirstLetter(_ctx.currentTab)), 1),
      _createVNode(_component_tm_search, {
        modelValue: _ctx.search,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        size: "small",
        placeholder: _ctx.searchPlaceholder,
        class: "w240px"
      }, null, 8, ["modelValue", "placeholder"])
    ]),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (item, i) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(item.itemType === 'tab' ? 'select-entity-content-tab' : 'select-entity-content-title'), _mergeProps({
          key: i,
          "btn-text": _ctx.tabBtnText,
          "square-icon": _ctx.tabSquareIcon
        }, item), null, 16, ["btn-text", "square-icon"]))
      }), 128))
    ])
  ]))
}