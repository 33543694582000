import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a5c6bae6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-entity-modal__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_entity_sidebar = _resolveComponent("select-entity-sidebar")!
  const _component_select_entity_content = _resolveComponent("select-entity-content")!
  const _component_select_entity_mobile = _resolveComponent("select-entity-mobile")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "selectEntity",
    "modal-title": _ctx.title,
    "no-footer": "",
    "without-content-padding": "",
    size: "xLarge"
  }, {
    "modal-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_select_entity_sidebar, {
          "current-tab": _ctx.currentTab,
          "onUpdate:current-tab": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTab) = $event)),
          categories: _ctx.categories
        }, null, 8, ["current-tab", "categories"]),
        _createVNode(_component_select_entity_content, {
          "current-tab": _ctx.currentTab,
          categories: _ctx.categories,
          "search-placeholder": _ctx.searchPlaceholder,
          "tab-btn-text": _ctx.tabBtnText,
          "tab-square-icon": _ctx.tabSquareIcon
        }, null, 8, ["current-tab", "categories", "search-placeholder", "tab-btn-text", "tab-square-icon"])
      ]),
      _createVNode(_component_select_entity_mobile, {
        class: "lt-md",
        categories: _ctx.categories,
        "search-placeholder": _ctx.searchPlaceholder,
        "tab-btn-text": _ctx.tabBtnText,
        "tab-square-icon": _ctx.tabSquareIcon
      }, null, 8, ["categories", "search-placeholder", "tab-btn-text", "tab-square-icon"])
    ]),
    _: 1
  }, 8, ["modal-title"]))
}