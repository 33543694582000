
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmEmoji from '@/components/shared/TmEmoji.vue'

export default defineComponent({
  components: {
    TmButton,
    TmEmoji,
  },
  props: {
    icon: {
      type: String,
    },
    emoji: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    btnText: {
      type: String,
    },
    squareIcon: {
      type: Boolean,
    },
    isAdded: {
      type: Boolean,
    },
  },
})
