
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import SelectEntitySidebar from '@/components/modals/shared/selectEntity/sidebar/SelectEntitySidebar.vue'
import SelectEntityContent from '@/components/modals/shared/selectEntity/SelectEntityContent.vue'
import type { SelectEntityCategoryType } from '@/definitions/modals/shared/selectEntity/type'
import type { PropType } from 'vue'
import { pinnedViewsCategories } from '@/definitions/home/data'
import SelectEntityMobile from '@/components/modals/shared/selectEntity/SelectEntityMobile.vue'

export default defineComponent({
  components: {
    SelectEntityMobile,
    SelectEntityContent,
    SelectEntitySidebar,
    TmModal,
  },
  props: {
    title: {
      type: String,
      default: 'Add pinned view',
    },
    categories: {
      type: Array as PropType<SelectEntityCategoryType[]>,
      default: () => pinnedViewsCategories,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search views',
    },
    tabBtnText: {
      type: String,
      default: 'Add',
    },
    tabSquareIcon: {
      type: Boolean,
    },
  },
  setup(props) {
    const currentTab = ref(props.categories[0].name)

    return {
      currentTab,
    }
  },
})
