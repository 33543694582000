
import { defineComponent, ref } from 'vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import SelectEntityContentTab from '@/components/modals/shared/selectEntity/SelectEntityContentTab.vue'
import SelectEntityContentTitle from '@/components/modals/shared/selectEntity/SelectEntityContentTitle.vue'
import { capitalizeFirstLetter } from '@/services/string'
import type { PropType } from 'vue'
import type { SelectEntityCategoryType } from '@/definitions/modals/shared/selectEntity/type'
import TmExpansionSection from '@/components/shared/tmExpansion/TmExpansionSection.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    TmExpansionSection,
    SelectEntityContentTab,
    SelectEntityContentTitle,
    TmSearch,
  },
  props: {
    categories: {
      type: Array as PropType<SelectEntityCategoryType[]>,
      required: true,
    },
    searchPlaceholder: {
      type: String,
    },
    tabBtnText: {
      type: String,
    },
    tabSquareIcon: {
      type: Boolean,
    },
  },
  setup(props) {
    const search = ref('')
    const localCategories = ref(props.categories)

    return {
      localCategories,
      search,
      capitalizeFirstLetter,
    }
  },
})
