
import { defineComponent } from 'vue'
import SelectEntitySidebarTab from '@/components/modals/shared/selectEntity/sidebar/SelectEntitySidebarTab.vue'
import type { PropType } from 'vue'
import type { SelectEntityCategoryType } from '@/definitions/modals/shared/selectEntity/type'

export default defineComponent({
  components: {
    SelectEntitySidebarTab,
  },
  props: {
    currentTab: {
      type: String,
    },
    categories: {
      type: Array as PropType<SelectEntityCategoryType[]>,
      required: true,
    },
  },
  emits: ['update:current-tab'],
})
