import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["select-entity-sidebar-tab", {
      'select-entity-sidebar-tab--active': _ctx.isActive,
    }])
  }, [
    _createVNode(_component_tm_icon, {
      name: _ctx.icon,
      class: "mr-2"
    }, null, 8, ["name"]),
    _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
  ], 2))
}