import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57c67080"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-entity-content-tab__body" }
const _hoisted_2 = { class: "flex-grow-1 overflow-hidden" }
const _hoisted_3 = { class: "body-text-semi-bold-14 lh-20 truncate" }
const _hoisted_4 = {
  key: 0,
  class: "lh-16 body-text-regular-12 distinct--text mt-2px"
}
const _hoisted_5 = { class: "select-entity-content-tab__btn" }
const _hoisted_6 = {
  key: 1,
  class: "caption-12 light--text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_emoji = _resolveComponent("tm-emoji")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["select-entity-content-tab", {
      'select-entity-content-tab--large': _ctx.text,
    }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["select-entity-content-tab__icon", {
        'select-entity-content-tab__icon--square': _ctx.squareIcon,
      }])
    }, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_tm_icon, {
            key: 0,
            size: _ctx.squareIcon ? 'xxxLarge' : 'xLarge',
            class: "primary--text",
            name: _ctx.icon
          }, null, 8, ["size", "name"]))
        : _createCommentVNode("", true),
      (_ctx.emoji)
        ? (_openBlock(), _createBlock(_component_tm_emoji, {
            key: 1,
            size: "xLarge",
            name: _ctx.emoji
          }, null, 8, ["name"]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
        (_ctx.text)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.text), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (!_ctx.isAdded)
          ? (_openBlock(), _createBlock(_component_tm_button, {
              key: 0,
              color: "primary",
              size: "large"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.btnText), 1)
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, " Added "))
      ])
    ])
  ], 2))
}